import { Route, Routes,Navigate} from "react-router-dom";
import Header from "./components/Header/Header";
import HomePage from "./page/HomePage/index";
import SelfService from "./page/SelfService";
import QrCode from './page/QrCode'
import LandingPage from './page'
import './App.css';

import { useCookies } from 'react-cookie'
import { useEffect, useState } from "react";
import TahnkYou from "./page/ThankYou/ThankYou";

function App() {
  const [borderState,setBorderState]=useState(false)
  const [width, setWidth] = useState<number>(window.innerWidth);
  const handleWindowSizeChange = () => {
    console.log(window.innerWidth)
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
const isMobile = width <= 768;
  return (
    <div>
      <Routes>
      <Route path="/" element={<LandingPage/>} /> 
      <Route path="/qrcode" element={<QrCode/>} /> 
      {/* <Route path="/branding" element={<HomePage/>} /> 
      <Route path="/branding/self-service" element={<SelfService/>} />
      <Route path="/thank-you" element={<TahnkYou setBorderState={setBorderState}/>}/> */}
      </Routes>
      {/* <Footers/> */}
    </div>
  );
}
export const NotFound = () => {
  return <div><h1> 404 page not found </h1> </div>
}
export default App;
