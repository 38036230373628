import React, { useEffect } from "react";
import QRCode from "qrcode.react";
import './HomePage/Home.css'
interface Props {
}
const HomePage: React.FC<Props> = ({
}) => {
    return (
        <div className="qrcode_container">
            <div className="qrcode">
                <QRCode
                    className="qrcode"
                    value="https://pramerica-kiosk.pages.dev" style={{ marginRight: 50, width: "400px", height: "400px" }} />
            </div>
        </div>
    )
}
export default HomePage