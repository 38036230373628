import React from "react";
import logo from "../Assets/pramerica.jpg";
import '../page/HomePage/Home.css'
import PramericaLogo from '../Assets/pramericalogo.png'
const LogoPage = () => {
    return (
        <div id="block_container">
            <div id="block1" style={{marginTop:"5px",marginLeft:"5px"}}>  
            <img style={{width:"100%",height:"35px"}} src={PramericaLogo}/>
            </div>
        </div>
    )
}
export default LogoPage