import React, { useState,useEffect } from "react";
import Home from "./HomePage";
import SelfService from "./SelfService";
import axios from 'axios'
interface Props {

}
var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  function success(pos:any) {
    var crd = pos.coords;
  console.log(crd)
    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }
  
  function errors(err:any) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

const LandingPage: React.FC<Props> = ({ }) => {
    const [ip,setIP] = useState('');
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res,"========>s");
        setIP(res.data.ip);
      };
    
    useEffect(()=>{
        //passing getData method to the lifecycle method
        getData()
    },[])
    useEffect(()=>{
        if (navigator.geolocation) {
            navigator.permissions
              .query({ name: "geolocation" })
              .then(function (result) {
                if (result.state === "granted") {
                  console.log(result.state);
                  //If granted then you can directly call your function here
                  navigator.geolocation.getCurrentPosition(success);
                } else if (result.state === "prompt") {
                  navigator.geolocation.getCurrentPosition(success, errors, options);
                } else if (result.state === "denied") {
                  //If denied then you have to show instructions to enable location
                }
                result.onchange = function () {
                  console.log(result.state);
                };
              });
          } else {
            alert("Sorry Not available!");
          }
    },[])
    const [page, setPage] = useState<any>("Home")
    console.log(ip,"ip")
    return (
        <main>
            {page === "Home" ? (
                <Home setPage={setPage}/>
            ):<SelfService setPage={setPage}/>}

        </main>
    )
}

const mapStateToProps = (state: any) => ({

})
export default LandingPage